<template>
  <div>
    <template v-if="currentRoute.startsWith('/forgotPassword')">
      <!-- Prikazuje se samo screen za forgot password -->
      <forgotPassword></forgotPassword>
    </template>
    <template v-else>
    <template v-if="loggedIn">
    <v-app>
      <div v-if="!cookiesAccepted" class="cookie-law-wrap">
        <div class="cookie-law">
          <div class="cookie-law-content">
            <div class="cookie-law-msg">
              This website uses cookies. Click 'Accept' to continue.
            </div>
            <div class="cookie-law-button">
              <button class="cookie-law-btn" @click="acceptCookies">
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
      <WelcomeModal v-if="loggedIn" />

      <v-navigation-drawer app v-model="drawer" class="drawer-style" :clipped="$vuetify.breakpoint.lgAndUp" disable-resize-watcher style="background: linear-gradient(125.73deg, #232F99 8.75%, rgba(66, 35, 153, 0.82) 34.42%) !important;">
        <v-list>
          <v-list-item-group>
            <!-- Logo -->
            <v-list-item>
              <v-img :src="require('@/assets/Staticlogo_katibworks.webp')" max-width="120" class="my-8 mx-auto rounded-circle" contain></v-img>
              <!-- <v-img src="https://amz.mpanel.app/instances/amz/logo-animation.gif" max-width="150" class="my-8 mx-auto rounded-circle" contain></v-img> -->
            </v-list-item>
            
            <!-- Navigation Links -->
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item router :to="{ name: 'live' }" v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon color="white">mdi-video</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="nav-item-text white--text">Live</v-list-item-title>
                </v-list-item>
              </template>
              <span>Transcriptions of live recordings</span>
            </v-tooltip> -->

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item router :to="{ name: 'myRecordings' }" v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon color="white">mdi-record</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="nav-item-text white--text">My recordings</v-list-item-title>
                </v-list-item>
              </template>
              <span>From extension and mobile app</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item router :to="{ name: 'fileUpload' }" v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon color="white">mdi-cloud-upload</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="nav-item-text white--text">My uploads</v-list-item-title>
                </v-list-item>
              </template>
              <span>Upload and transcribe your files</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item router :to="{ name: 'myArticles' }" v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon color="white">mdi-newspaper</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="nav-item-text white--text">My articles</v-list-item-title>
                            <p class="sticker">🆕</p>
                </v-list-item>
              </template>
              <span>View and manage your articles</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item router :to="{ name: 'textToSpeech' }" v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon color="white">mdi-microphone-message</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="nav-item-text white--text">Text to speech</v-list-item-title>
                              <p class="sticker">🆕</p>
                </v-list-item>
              </template>
              <span>Convert text to speech</span>
            </v-tooltip>

            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon color="white">mdi-account-group</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="nav-item-text white--text">My meetings</v-list-item-title>
                </v-list-item>
              </template>
              <span>Not available in demo</span>
            </v-tooltip> -->

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item router :to="{name: 'translate'}" v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon color="white">mdi-translate</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="nav-item-text white--text">My translations</v-list-item-title>
                  <!-- <p class="sticker">🆕</p> -->
                </v-list-item>
              </template>
              <span>Translations of transcribed content</span>
            </v-tooltip>

            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon color="white">mdi-ear-hearing</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="nav-item-text white--text">My wakewords</v-list-item-title>
                </v-list-item>
              </template>
              <span>Not available in the demo</span>
            </v-tooltip> -->

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item router :to="{ name: 'subtitles' }" v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon color="white">mdi-text</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="nav-item-text white--text">Subtitles</v-list-item-title>
                </v-list-item>
              </template>
              <span>Subtitles</span>
            </v-tooltip>

            <hr>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item router :to="{ name: 'groupTranscripts' }" v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon color="white">mdi-script-text</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="nav-item-text white--text">Group Transcripts</v-list-item-title>
                </v-list-item>
              </template>
              <span>Transcriptions of all users in your group</span>
            </v-tooltip>


            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item router :to="{ name: 'folders' }" v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon color="white">mdi-folder</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="nav-item-text white--text">My folders</v-list-item-title>
                </v-list-item>
              </template>
              <span>Pack transcriptions into folders</span>
            </v-tooltip>
            
          </v-list-item-group>
        </v-list>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item style="position: absolute;bottom: 70px;width: 100%;" href="https://app-works.app/oneArticle/42" v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon color="white">mdi-newspaper-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="nav-item-text white--text">What's new?</v-list-item-title>
            </v-list-item>
          </template>
          <span>Check out what's new in Litteraworks</span>
        </v-tooltip> -->
        <ChatBot />
      </v-navigation-drawer>

      <v-app-bar app class="app-bar"  height="80px">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="app-bar-title">{{ currentGreeting }}, {{user.name}}</v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- Search Field -->
 
        <Search />

        <v-spacer></v-spacer>

        <!-- Additional elements -->
        <div class="page-title-actions">

          <button type="button" class="btn-shadow btn btn-info time-btn watchBox">
            ⌚ {{currentTime}}
          </button>
          <router-link v-if="getUserRoleId() === 1" to="/editPanel">
            <button type="button" class="btn-shadow btn btn-info time-btn ml-2">
              <span class="mdi mdi-pencil-outline"></span> Admin Panel
            </button>
          </router-link>

          <!-- <button type="button" class="btn logOut"  @click="clearLocalStorage">
            🚪
          </button> -->
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on" class="px-1 ml-2">
                  <v-icon color="">mdi-account-circle</v-icon>
                  <v-icon color="">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="userProfileRoute">
                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openSettings">
                  <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Settings</v-list-item-title>
                </v-list-item>
                <v-list-item @click="clearLocalStorage">
                  <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <!-- <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="rounded-circle mx-3"
                v-bind="attrs"
                v-on="on"
                size="35"
              >mdi-account-circle</v-icon>
            </template>
            <v-list>
              <v-list-item>
                <button type="button" class="btn"  @click="clearLocalStorage">
                 LogOut
                </button>
              </v-list-item>
            </v-list>
          </v-dialog> -->

        </div>
      </v-app-bar>

      <v-main>
        <!-- Router view -->
        <router-view></router-view>
      </v-main>

      <!-- Settings Drawer -->
      <v-navigation-drawer
        v-model="settingsDrawer"
        fixed
        right
        temporary
        width="600"
        class="settings-drawer"
      >
        <v-card flat class="mt-5" v-if="isOrganizationOne">
          <v-toolbar flat dense class="drawer-header">
            <v-toolbar-title class="text-h6 d-flex align-center">
              <v-icon left>mdi-cog</v-icon>
              Settings
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeSettingsDrawer">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pa-6">
            <UserSettings ref="userSettings" />
          </v-card-text>
        </v-card>
      </v-navigation-drawer>

    </v-app>
  </template>
  <template v-else>
    <Login @login-success="loggedIn = true"></Login>
  </template>
</template>
  </div>
</template>

<script>
// import UserLogin from "@/components/UserLogin.vue";
import Login from "@/components/LoginView.vue"
import WelcomeModal from "@/components/WelcomeModal.vue"
import Search from "@/components/Search.vue"
import ChatBot from "@/components/ChatBot.vue"
import forgotPassword from "@/components/ForgotPassword.vue";
import { mapActions } from "vuex";
import { startTour } from '@/plugins/tourStepsLayout.js';
import UserSettings from "@/components/UserSettings.vue";

export default {
  components: {
    // UserLogin,
    Login,
    WelcomeModal,
    Search,
    forgotPassword,
    ChatBot,
    UserSettings
  },
  data: () => ({
    loggedIn: false,
    drawer: true,
    searchQuery: "",
    dialog: false,
    currentTime: new Date().toLocaleTimeString(),
    user: "",
    cookiesAccepted: false,
    currentGreeting: 'Hi',
    settingsDrawer: false,
    isOrganizationOne: false
  }),
  computed: {
    currentRoute() {
      return this.$route.path;
    }
  },
  methods: {
    chooseRandomGreeting() {
      const greetings = ['Hola', 'Bonjour', 'Hallo', 'Ciao', 'Привет', 'Hi'];
      this.currentGreeting = greetings[Math.floor(Math.random() * greetings.length)];
    },
    userProfileRoute() {
      this.$router.push({ name: "userProfile" });
    },
    initiateTour() {
      startTour();
    },
    getUserRoleId() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.role_id) {
        return user.role_id;
      }
      return null;
    },
    doSearch() {
      this.$store.dispatch("search", this.searchQuery);
    },
    clearLocalStorage() {
      // Sačuvamo samo hasSeenWelcomeModal cookie jer njega koristimo
      const hasSeenModalCookie = document.cookie.split(';').find(cookie => 
        cookie.trim().startsWith('hasSeenWelcomeModal=')
      );
      
      localStorage.clear();
      
      // Ne moramo vraćati CookieLawSeen jer je već u cookies
      if (hasSeenModalCookie) {
        // Ako želimo da zadržimo welcome modal status
        document.cookie = hasSeenModalCookie.trim() + ';path=/;max-age=31536000';
      }
      
      window.location.reload();
    },
    checkCookiesAccepted() {
      const cookies = document.cookie.split(';');
      const cookieLawCookie = cookies.find(cookie => cookie.trim().startsWith('CookieLawSeen='));
      if (cookieLawCookie) {
        this.cookiesAccepted = true;
      }
    },
    acceptCookies() {
      document.cookie = `CookieLawSeen=true;path=/;max-age=31536000`; // 1 godina
      this.cookiesAccepted = true;
    },
    ...mapActions(["fetchGroups"]),
    ...mapActions(["fetchUsers"]),
    ...mapActions(["fetchFolders"]),
    openSettings() {
      this.settingsDrawer = true;
      this.$nextTick(() => {
        if (this.$refs.userSettings) {
          this.$refs.userSettings.getSettings();
        }
      });
    },
    closeSettingsDrawer() {
      this.settingsDrawer = false;
    }
  },
  mounted() {
    // this.initiateTour();
    this.fetchGroups();
    this.fetchUsers();
    this.fetchFolders();
    this.checkCookiesAccepted();
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.user = user;
      this.loggedIn = true;
    } else {
      // preusmerite korisnika na login stranicu
      this.loggedIn = false;
    }
    // this.checkCookiesAccepted();
  },
  created() {
    this.chooseRandomGreeting();
    setInterval(() => {
      this.currentTime = new Date().toLocaleTimeString();
    }, 1000);
    const user = JSON.parse(localStorage.getItem('user'));
    this.isOrganizationOne = user?.organization?.allow_user_settings === 1 || user?.role_id === 3;
  }
};
</script>

<style>
@import "@/assets/css/style.css";
footer {
  background: transparent;
  height: 0;
}
hr {
    color: white;
    margin: 10px 16px;
}

/* Cookie Law stilovi */
.cookie-law-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgb(255, 255, 255);
}

.cookie-law {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

.cookie-law-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.cookie-law-msg {
  margin-right: 20px;
}

.cookie-law-button {
  flex-shrink: 0;
}

.cookie-law-btn {
  background: #262f99;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.cookie-law-btn:hover {
  background: #313ecc;
}

.settings-drawer {
  background-color: #ffffff !important;
}

.settings-drawer .drawer-header {
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

.settings-drawer .v-navigation-drawer__content {
  padding-top: 0 !important;
  overflow-y: auto;
}

.settings-drawer :deep(.v-input--dense >>> .v-input__slot) {
  min-height: 40px !important;
}

.settings-drawer :deep(.v-text-field--filled >>> .v-text-field__details) {
  margin-bottom: 8px;
}

.settings-drawer :deep(.v-input--selection-controls) {
  margin-top: 0;
}

/* existing styles... */
</style>
