<template>
  <v-dialog v-model="showModal" max-width="500px" persistent>
    <v-card>
      <!-- <v-card-title class="headline my-2">We have prepared new functionalities for you!</v-card-title> -->
      <v-card-text style="padding: 0!important;">
        <v-stepper v-model="currentStep">
          <!-- Klikabilni koraci -->
          <v-stepper-header>
            <!-- Step 1 -->
            <v-stepper-step
              :complete="currentStep > 1"
              step="1"
              color="#6c62ef"
              @click="currentStep = 1"
            >
              Step 1
            </v-stepper-step>
            <v-divider></v-divider>
            <!-- Step 2 -->
            <v-stepper-step
              :complete="currentStep > 2"
              step="2"
              color="#6c62ef"
              @click="currentStep = 2"
            >
              Step 2
            </v-stepper-step>
            <v-divider></v-divider>
            <!-- Step 3 -->
            <v-stepper-step
              step="3"
              color="#6c62ef"
              @click="currentStep = 3"
            >
              Step 3
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- Step 1 Content -->
            <v-stepper-content step="1">
              <h5><b>AI-created Articles</b></h5>
              <p class="text-welcome">
                Generate articles (news article, interview) directly from the transcription. LitteraWorks will write a basic article, which you will later turn into something interesting for the reader.
              </p>
              <p class="text-welcome mb-4">
                Generate multiple articles from a single transcription.
              </p>
              <img class="show" src="https://app-works.app/image/cache/large/files/images/view-2.png?crop=true">
              <v-btn color="primary" @click="nextStep" class="mt-5">Next</v-btn>
            </v-stepper-content>

            <!-- Step 2 Content -->
            <v-stepper-content step="2">
              <h5><b>Enhanced Article Management</b></h5>
              <p class="text-welcome">
                View and manage articles in one place - copy as text or HTML, export to mPanel or other CMS, delete them...
              </p>
              <p class="text-welcome mb-4">
                Access all articles from the new Articles Page in the left menu.
              </p>
              <img class="show" src="https://app-works.app/image/cache/large/files/images/view-1.png?crop=true">
              <v-btn color="primary" @click="nextStep" class="mt-5">Next</v-btn>
            </v-stepper-content>

            <!-- Step 3 Content -->
            <v-stepper-content step="3">
              <h5><b>Advanced Settings</b></h5>
              <p class="text-welcome">
                You are now able to adjust parameters for transcription style and language, summarization parameters, subtitle length and rows etc.
              </p>
              <p class="text-welcome mb-4">
                Take control over your transcriptions - to fit your needs.
              </p>
              <img class="show" src="https://app-works.app/image/cache/large/files/images/view-3.png?crop=true">
              <v-btn color="primary" @click="closeModal" class="mt-5">Close</v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      currentStep: 1,
    };
  },
  mounted() {
    // Provera da li postoji cookie
    const cookies = document.cookie.split(';');
    const hasSeenModalCookie = cookies.find(cookie => cookie.trim().startsWith('hasSeenWelcomeModal='));
    
    if (!hasSeenModalCookie) {
      this.showModal = true;
    }
  },
  methods: {
    closeModal() {
      this.showModal = false;
      // Postavljamo cookie sa rokom trajanja od godinu dana
      document.cookie = `hasSeenWelcomeModal=true;path=/;max-age=31536000`;
    },
    nextStep() {
      if (this.currentStep < 3) {
        this.currentStep += 1;
      }
    },
  },
};
</script>
<style>
.v-stepper__header {
    box-shadow: 0 0px 10px 0px rgba(0, 0, 0, .2)!important;
}
.show {
    width: 100%!important;
    margin: 0px 0 20px 0;
}
.text-welcome {
  font-weight: 400!important;
  color:#4e4e4e;
}
.height-1 {
  height: 324px;
}
</style>
