<template>
  <div class="settings-container">
    <v-card flat>
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Transcription Language</v-list-item-title>
              <v-list-item-subtitle>
                Choose language for transcription
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-autocomplete
                v-model="settings.transcription_language"
                :items="itemsLang"
                dense
                filled
                label="Choice language"
              ></v-autocomplete>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Diarization Enabled</v-list-item-title>
              <v-list-item-subtitle>
                Enable speaker identification
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch 
                v-model="settings.diarization_enabled" 
                color="#7f5dee"
              ></v-switch>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Recording Type</v-list-item-title>
              <v-list-item-subtitle>
                Select type of recording
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-autocomplete
                v-model="settings.recording_type"
                :items="['Press conference', 'Podcast', 'Lecture', 'Interview', 'Short quote']"
                dense
                filled
                clearable
                label="Choice recording type"
              ></v-autocomplete>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Lines Per Subtitle</v-list-item-title>
              <v-list-item-subtitle>
                Number of lines that will appear in each subtitle block
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-text-field
                v-model="settings.lines_per_subtitle"
                type="number"
                dense
                filled
                label="Lines per subtitle"
              ></v-text-field>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Characters Per Subtitle</v-list-item-title>
              <v-list-item-subtitle>
                Maximum number of characters allowed in a single subtitle
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-text-field
                v-model="settings.characters_per_subtitle"
                type="number"
                dense
                filled
                label="Characters per subtitle"
              ></v-text-field>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>HTML Article Format</v-list-item-title>
              <v-list-item-subtitle>
                Enable HTML formatting for better article structure and styling
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch 
                v-model="settings.is_article_html" 
                color="#7f5dee"
              ></v-switch>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Summarization Language</v-list-item-title>
              <v-list-item-subtitle>
                Choose language for summarization output
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-autocomplete
                v-model="settings.summarization_language"
                :items="itemsLang"
                dense
                filled
                clearable
                label="Choice language"
              ></v-autocomplete>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Summarization Tone</v-list-item-title>
              <v-list-item-subtitle>
                Choose the writing style and tone for your summaries
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-autocomplete
                v-model="settings.summarization_tone"
                :items="itemsTone"
                dense
                filled
                clearable
                label="Choice tone"
              ></v-autocomplete>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Summarization Length</v-list-item-title>
              <v-list-item-subtitle>
                Set how detailed or concise you want your summaries to be
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-autocomplete
                v-model="settings.summarization_length"
                :items="itemsLength"
                dense
                filled
                clearable
                label="Choice length"
              ></v-autocomplete>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Summarization Type</v-list-item-title>
              <v-list-item-subtitle>
                Choose how you want your summary to be formatted and structured
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-autocomplete
                v-model="settings.summarization_type"
                :items="itemsSumaryType"
                dense
                filled
                clearable
                label="Summary type"
              ></v-autocomplete>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Additional Context</v-list-item-title>
              <v-list-item-subtitle>
                Add extra information to help improve summarization accuracy
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-text-field
                v-model="settings.additional_context"
                filled
                clearable
                label="Additional context provided by the user"
              ></v-text-field>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item v-if="isOrganizationOne">
            <v-list-item-content>
              <v-list-item-title>Allow User Settings</v-list-item-title>
              <v-list-item-subtitle>
                Let individual users customize their own settings
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch 
                v-model="settings.allow_user_settings" 
                color="#7f5dee"
              ></v-switch>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions class="">
        <v-spacer></v-spacer>
        <v-btn
          color="#7f5dee"
          class="white--text"
          :loading="isSaving"
          @click="saveSettings"
        >
          Save Settings
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserSettings',
  data: () => ({
    settings: {
      organization_id: '',
      user_id: '',
      transcription_language: '',
      diarization_enabled: false,
      recording_type: '',
      lines_per_subtitle: null,
      characters_per_subtitle: null,
      is_article_html: false,
      summarization_language: '',
      summarization_tone: '',
      summarization_length: '',
      summarization_type: '',
      additional_context: '',
      allow_user_settings: false
    },
    itemsLang: [
      "English", "Chinese", "German", "Italian", "Spanish", "French",
      "Azerbaijani", "Bosnian", "Bulgarian", "Croatian", "Czech",
      "Danish", "Dutch", "Estonian", "Finnish", "Hungarian",
      "Japanese", "Korean", "Greek", "Latvian", "Lithuanian",
      "Macedonian", "Norwegian", "Portuguese", "Polish", "Romanian",
      "Russian", "Serbian", "Slovenian", "Slovak", "Swedish",
      "Turkish", "Ukrainian"
    ],
    itemsTone: ["Conversational", "Informative", "Persuasive"],
    itemsLength: ["Brief", "Detailed"],
    itemsSumaryType: ["Bullet-points", "Paragraphs"],
    isSaving: false,
    snackbar: {
      show: false,
      text: '',
      color: 'success'
    },
    isOrganizationOne: false
  }),
  async created() {
    await this.getSettings();
    const user = JSON.parse(localStorage.getItem('user'));
    this.isOrganizationOne = user?.role_id === 3;
  },
  methods: {
    async getSettings() {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const response = await axios.post('https://qira-worker.mwtatooine.net/api/backend/settings/getSettings', {
          user_id: user.id,
          organization_id: user.organization_id
        });
        
        if (response.data.success && response.data.settings) {
          const settings = {
            ...response.data.settings,
            diarization_enabled: Boolean(response.data.settings.diarization_enabled),
            is_article_html: Boolean(response.data.settings.is_article_html),
            summarization_tone: response.data.settings.summarization_tone || '',
            summarization_length: response.data.settings.summarization_length || '',
            summarization_type: response.data.settings.summarization_type || '',
            additional_context: response.data.settings.additional_context || ''
          };
          
          this.settings = settings;
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    },
    async saveSettings() {
      try {
        this.isSaving = true;
        const user = JSON.parse(localStorage.getItem('user'));
        
        const requiredFields = [
          'transcription_language',
          'diarization_enabled',
          'lines_per_subtitle',
          'characters_per_subtitle',
          'is_article_html'
        ];

        const missingFields = requiredFields.filter(field => {
          const value = this.settings[field];
          return value === null || value === undefined || value === '';
        });

        if (missingFields.length > 0) {
          this.showSnackbar('Please fill in all required fields', 'error');
          return;
        }

        const formData = new FormData();
        
        formData.append('user_id', user.id);
        formData.append('organization_id', user.organization_id);
        formData.append('transcription_language', this.settings.transcription_language);
        formData.append('diarization_enabled', this.settings.diarization_enabled ? '1' : '0');
        formData.append('recording_type', this.settings.recording_type);
        formData.append('lines_per_subtitle', this.settings.lines_per_subtitle);
        formData.append('characters_per_subtitle', this.settings.characters_per_subtitle);
        formData.append('is_article_html', this.settings.is_article_html ? '1' : '0');
        formData.append('summarization_language', this.settings.summarization_language || '');
        formData.append('summarization_tone', this.settings.summarization_tone || '');
        formData.append('summarization_length', this.settings.summarization_length || '');
        formData.append('summarization_type', this.settings.summarization_type || '');
        formData.append('additional_context', this.settings.additional_context || '');
        formData.append('allow_user_settings', this.settings.allow_user_settings ? '1' : '0');

        const response = await axios.post(
          'https://qira-worker.mwtatooine.net/api/backend/settings/postSettings',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data.success) {
          const currentUser = JSON.parse(localStorage.getItem('user'));
          currentUser.settings = {
            headers: {},
            original: {
              success: true,
              message: "Organization level settings",
              settings: response.data.settings
            },
            exception: null
          };
          
          localStorage.setItem('user', JSON.stringify(currentUser));
          this.showSnackbar('Settings saved successfully');
        } else {
          this.showSnackbar('Failed to save settings', 'error');
        }
      } catch (error) {
        console.error('Error saving settings:', error);
        this.showSnackbar('Error saving settings', 'error');
      } finally {
        this.isSaving = false;
      }
    },
    showSnackbar(text, color = 'success') {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    }
  }
};
</script>

<style scoped>
.settings-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

:deep(.v-text-field__details) {
  display: none;
}

:deep(.v-messages) {
  display: none;
}

:deep(.v-input__slot) {
  margin-bottom: 0 !important;
}

:deep(.v-list-item__title) {
  font-weight: 500;
  font-size: 0.95rem !important;
}

:deep(.v-list-item__subtitle) {
  font-size: 0.8rem !important;
  opacity: 0.7;
}

:deep(.v-input.v-input--dense) {
  margin-top: 0;
  padding-top: 0;
}

.v-btn {
  text-transform: none;
  font-weight: 500;
}
</style> 